@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --foreground-rgb: 0, 0, 0;
  --background-start-rgb: 214, 219, 220;
  --background-end-rgb: 255, 255, 255;
  --swiper-pagination-color: #00205b;
  --swiper-pagination-bullet-inactive-color: #cdd2de;
  --swiper-pagination-bullet-inactive-opacity: 1;
  --font-saira-semi-condensed: "var(--font-saira-semi-condensed)";
}

@media screen and (max-width: 1024px) {
  :root {
    --eswButtonBottom: 60px !important;
  }
}

@media (prefers-color-scheme: dark) {
  html {
    color-scheme: "light";
    font-family: var(--font-saira), sans-serif;
  }
}

html {
  color-scheme: "light";
  font-family: var(--font-saira), sans-serif;
}

@layer components {
  .page-link {
    /* prettier-ignore */
    @apply text-black focus:ring-1 disabled:text-black-40 fill-black active:text-dijon enabled:hover:bg-black-10 disabled:cursor-not-allowed disabled:fill-black-20 relative inline-flex items-center justify-center w-8 h-8 transition bg-white rounded outline-none select-none;
  }
  .page-link-arrows {
    /* prettier-ignore */
    @apply w-fit h-fit enabled:active:fill-dijon gap-[10px] flex items-center px-2 py-1;
  }
  .content-layout {
    /* prettier-ignore */
    @apply w-full mx-auto xl:max-w-screen-2xl lg:max-w-screen-xl sm:max-w-screen-md md:max-w-screen-lg sm:py-[80px] py-[48px] px-4 lg:px-8 xl:px-[80px] 2xl:px-[104px];
  }
}

@layer utilities {
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }
  .no-scrollbar {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }

  .h-unset {
    height: unset;
  }

  .w-unset {
    width: unset;
  }
}

* {
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

input[type="search"]::-webkit-search-cancel-button {
  -webkit-appearance: none;
  height: 16px;
  width: 16px;
  margin-left: 0.4em;
  background-image: url("data:image/svg+xml;utf8,<svg aria-label='clear icon' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 22 22' fill='slate-500'><path d='M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z'/></svg>");
  cursor: pointer;
}

.testimonialWrapper .swiper-wrapper {
  padding-bottom: 68px !important;
}

.testimonialWrapper .swiper-pagination {
  bottom: 0px !important;
  top: unset !important;
}

.testimonialWrapper .swiper-pagination-bullet {
  background: #334d7c;
}

.testimonialWrapper .swiper-pagination-bullet-active {
  background: #ff9e1b;
}

.ring-2 {
  --tw-ring-offset-shadow: 0 0 #ffffff;
}

.animation-pulse {
  animation: pulse 2s infinite;
}

@keyframes pulse {
  0% {
    transform: scale(0.8);
    box-shadow: 0 0 0 0 #f8dd75;
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 30px rgba(238, 178, 13, 0);
  }

  100% {
    transform: scale(0.8);
    box-shadow: 0 0 0 30px rgba(238, 178, 13, 0);
  }
}

.searchDots:nth-of-type(2) {
  animation-delay: 0.2s;
}

.searchDots:nth-of-type(3) {
  animation-delay: 0.3s;
}

@keyframes dot {
  0% {
    background-color: white;
    transform: scale(1);
  }
  50% {
    background-color: #00205b;
    transform: scale(1.3);
  }
  100% {
    background-color: white;
    transform: scale(1);
  }
}

/** google map marker */
.advancedMarker {
  cursor: pointer;
}

/** google map InfoWindow arrow */
.gm-style-iw-tc {
  display: none;
}

/** google map InfoWindow close button */
button.gm-ui-hover-effect {
  top: 0 !important;
  right: 0 !important;
  height: 18px !important;
  width: 18px !important;
}

button.gm-ui-hover-effect span {
  background-image: url("/icons/close.png");
  background-color: white !important;

  mask-image: none !important;
  mask-mode: none !important;
  height: 18px !important;
  width: 18px !important;
  margin: 0 !important;
}

/** maps info window */
.gm-style-iw-c {
  transform-origin: top left !important;
  border-radius: 3px !important;
  padding: 16px 24px !important;
  translate: 70% 50%;
  animation: info-win 0.25s ease-in;
}

@keyframes info-win {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

/** google places list */
.pac-icon {
  display: none;
}

.pac-logo:after {
  display: none;
}

.pac-container {
  padding: 16px;
  margin-top: 8px;
  /* box-shadow: 2px 2px 6px #888888; */
  /* border: 1px solid #e6eef7; */
}

.pac-item {
  padding: 2px 8px;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
}

.pac-item:hover {
  background-color: #e6eef7;
}

.pac-item,
.pac-item-query {
  font-size: 16px;
}
.pac-item-query {
  font-size: 16px;
  font-weight: 600;
}

/* google maps infoWindow close button on mobile */
button.close-infoWindow-custom-button {
  position: absolute;
  top: 20px;
  right: 14px;

  display: flex;
  align-items: center;
  justify-content: center;
}
/* google maps infoWindow close button on mobile */
button.close-infoWindow-custom-button span {
  background-image: url("/icons/close.png");
  background-color: white;
  height: 18px;
  width: 18px;
}

.richTextList p {
  padding-left: 8px;
  display: inline;
}

.richTextList li {
  padding-left: 8px;
  margin-bottom: 8px;
}

.gridHighlights,
.gridHighlights_3 {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  grid-gap: 20px;
  align-items: stretch;
}

@media (min-width: 1024px) {
  .gridHighlights_3 {
    grid-template-columns: repeat(3, minmax(280px, 1fr));
  }
}

* {
  outline-width: thin;
}

.spinner-custom {
  display: none !important;
}

@media only screen and (min-width: 1024px) {
  .spinner-custom {
    display: block !important;
  }
}

.grid-background {
  /* background: url("/grid.png");
  background-repeat: repeat;
  background-size: 1458px 808px; */

  background-size: 17px 17px;
  background-image: linear-gradient(to right, rgba(128, 128, 128, 0.26) 1px, transparent 1px),
    linear-gradient(to bottom, rgba(128, 128, 128, 0.26) 1px, transparent 1px);
}

.worksite-arrow {
  position: absolute;
  cursor: pointer;
  align-items: center;
  gap: 12px;
  top: calc(50% - 20px);
  background-color: #00205b;
  border-radius: 6px;
}

.worksite-arrow:hover {
  background-color: #334d7c;
}

.worksite-button {
  /* position: absolute;
  display: flex;
  cursor: pointer;
  align-items: center;
  gap: 12px;
  bottom: 10%;
  left: calc(50% - 20px);
  background-color: #00205b;
  color: white;
  padding: 12px 24px;
  line-height: 170%;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
  font-size: 14px;
  font-weight: 500; */

  position: absolute;
  bottom: 10%;
  left: calc(50% - 70px);
  z-index: 40;
}

.worksite-button:hover {
  background-color: #334d7c;
}

.headingUnderline {
  font-size: calc(100% - 8px);
}

.customInput input[type="search"]::-webkit-search-cancel-button {
  -webkit-appearance: none;
  height: 16px;
  width: 16px;
  margin-left: 0.4em;
  background-image: url("data:image/svg+xml;utf8,<svg aria-label='clear icon' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 22 22' fill='white'><path d='M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z'/></svg>");
  cursor: pointer;
}

.headerMediaHeight {
  height: calc(100svh - 64px);

  @media (min-width: 1024px) {
    height: calc(100svh - 185px);
  }
}

.videoHeaderHeight {
  height: calc(100svh - 64px);

  @media (min-width: 1024px) {
    height: calc(100svh - 145px);
  }
}

.industryCategoryHeader {
  max-height: calc(100svh - 161px);

  @media (min-width: 1024px) {
    max-height: calc(100svh - 263px);
  }
}

.megaMenuLinks {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 170%;
  letter-spacing: -0.1px;
  font-family: var(--font-saira);
}

.embeddedServiceHelpButton .helpButton .uiButton {
  background-color: #00205b !important;
  font-family: var(--font-saira-semi-condensed), sans-serif !important;
}
.embeddedServiceHelpButton .helpButton .uiButton:focus {
  outline: 1px solid #00205b !important;
}
.embeddedServiceHelpButton .assistiveText {
  font-family: var(--font-saira-semi-condensed) !important;
}
.embeddedServiceHelpButton .uiButton .helpButtonLabel .message {
  font-family: var(--font-saira-semi-condensed) !important;
}

.mostPopularColumnClass::after {
  position: absolute;
  top: -50px;
  left: 0;
  right: 0;
  height: 30px;
  background-color: #f9e491;
  z-index: 10;
}

.hideScrollbar {
  scroll-snap-type: x mandatory;
  scroll-behavior: smooth;
}

.hideScrollbar::-webkit-scrollbar {
  display: none;
}

.customSwiper .swiper-slide {
  height: unset !important;
}

.css-1q6at85-MuiInputBase-root-MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline {
  border-color: black;
}

.hiddenInput {
  height: 0;
}

.MuiTelInput-TextField fieldset {
  visibility: hidden;
}

.telInputCustom > div {
  width: 100%;
}

.telInputCustom {
  z-index: 0;
  border: 1px solid #898a8d;
  border-radius: 5px;
  height: 41px;
}

.customizationHeaderArrowsLeft {
  background: rgb(0, 32, 91);
  background: linear-gradient(90deg, rgba(0, 32, 91, 1) 0%, rgba(0, 32, 91, 1) 20%, rgba(0, 0, 0, 0) 100%);
}

.customizationHeaderArrowsRight {
  background: rgb(0, 32, 91);
  background: linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, rgba(0, 32, 91, 1) 80%, rgba(0, 32, 91, 1) 0%);
}

input[type="checkbox"] {
  min-width: 1rem !important;
  min-height: 1rem !important;
}

input[type="radio"] {
  appearance: none;
  background-color: #e6e9ef;
  border: 1px solid black;
  border-radius: 50%;
  cursor: pointer;
}

input[type="radio"]:checked {
  appearance: revert;
  accent-color: rgb(0, 0, 0);
}

.yarl__container {
  background-color: rgba(0, 0, 0, 0.9) !important;
}
.yarl__navigation_prev,
.yarl__navigation_next {
  display: none !important;
}

.selectContentRadix {
  max-width: var(--radix-select-content-available-width);
  z-index: 3000;
}

.customImgPlaceholder_NOT_USED {
  animation: focus-in 0.1s forwards ease;
}

@keyframes focus-in {
  0% {
    filter: blur(50px);
    transform: scale(0.95);
  }
  /* 50% {
    filter: blur(40px);
  } */
  100% {
    filter: blur(0);
    transform: scale(1);
  }
}

.formMessage_error {
  margin-top: calc(0px * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(0px * var(--tw-space-y-reverse));
  font-family: var(--font-saira);
  white-space: pre-wrap;
  line-height: 170%;
}
.formMessage_error:after {
  content: "\200b";
}

.grecaptcha-badge {
  visibility: hidden;
}

#og_iframe_66f4572c3c4e618ea82c017a span,
#og_iframe_66f4572c3c4e618ea82c017a label,
.leadgenWithTitle label.leadfrom_title {
  color: #ff9e1b !important;
  font-family: var(--font-saira-semi-condensed);
}

.op-interactive {
  width: 100% !important;
}

.MuiTelInput {
  font-family: var(--font-saira);
}

.MuiTelInput-IconButton {
  display: none !important;
}

.MuiInputBase-adornedStart {
  padding-left: 0 !important;
}

.MuiInputAdornment-sizeMedium {
  background-color: red !important;
}

.MuiInputBase-input::placeholder {
  color: #64748b !important;
  opacity: 1;
  font-family: var(--font-saira);
}

#smxFeedbackBtn {
  -webkit-transform-origin: 50% 51%;
  transform-origin: 50% 51%;
}

/* .embeddedServiceHelpButton .helpButton {
  bottom: 52px !important;
} */

.MuiInputAdornment-sizeMedium {
  /* display: none !important; */
  background-color: transparent !important;
  padding-left: 10px !important;
}

.telInputCustom > div {
  width: calc(100% - 3px);
}

/* Homepage Blog Carousel Start */
.control_enable:disabled {
  border: 1px solid #ccc;
}
/* Homepage Blog Carousel End */
